






























import Vue from 'vue';
import Component from 'vue-class-component';
import { Global } from '@/store';
import { Prop } from 'vue-property-decorator';
import API from '@/modules/API';

@Component({})
export default class DeviceMetadata extends Vue {
    @Prop() deviceNetworkingData;
    @Prop() currentDevice;

    @Global.State('lang') lang;
    @Global.Action('throwNotify') throwNotify;

    async handleBaseStationClicked(){
        if (this.deviceNetworkingData.Cell_ID && this.deviceNetworkingData.LAC && this.deviceNetworkingData.MCC && this.deviceNetworkingData.MNC){
            try{
                const url = `https://www.googleapis.com/geolocation/v1/geolocate`;
                const body = {
                    cellTowers: [{
                        cellId: this.deviceNetworkingData.Cell_ID,
                        locationAreaCode: this.deviceNetworkingData.LAC,
                        mobileCountryCode: this.deviceNetworkingData.MCC,
                        mobileNetworkCode: this.deviceNetworkingData.MNC
                    }]
                };
                const antennaLocation = await API.googleAPI(url, 'POST', body);
                this.$emit('antennaChanged', antennaLocation.location);
            }catch (e){}
        }else{
            const message_text = `Could not find antenna for this device due to info missing`;
            const message = {type: 'warning', title: 'Warning!', text: message_text };
            this.throwNotify(message);
        }
        
    }

}
