

































































import Vue from 'vue';
import Component from 'vue-class-component';
import {Global} from '@/store';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class DeviceNetworkingInfo extends Vue {
    @Prop() deviceNetworkingData;
    @Prop() currentDevice;
    @Prop() devicesMetadata;

    @Global.State('lang') lang;

    lac_devices = [];
    cellid_devices = [];

    mounted() {
        if (this.deviceNetworkingData.LAC) {
            this.lac_devices = [...this.devicesMetadata.values()].filter((device) => device.LAC === this.deviceNetworkingData.LAC).map((device) => device.id);
        }
        if (this.deviceNetworkingData.Cell_ID){
            this.cellid_devices =  [...this.devicesMetadata.values()].filter((device) => device.Cell_ID === this.deviceNetworkingData.Cell_ID).map((device) => device.id);
        }
    }

    handleLacClicked(){
        if (this.deviceNetworkingData.LAC){
            this.$emit('selectedChanged', this.lac_devices);
        }
    }

    handleCellIdClicked(){
        if (this.deviceNetworkingData.Cell_ID){
            this.$emit('selectedChanged', this.cellid_devices);
        }
    }

}
